body {
  background: #fff;
  color: #06225c;
  font-family: "Arial";
}

.row {
  margin-right: 0em;
}

a.cLink {
  text-decoration: none;
}

input:focus {
  outline: none;
}

select.form-control, select.form-control:focus {
  color: #06225c;
  background: #ffffff;
}

.form-control:focus {
  box-shadow: inset 0 -1px 0 #ffffff;
}

.submitButton {
  color: #06225c;
  background: #63d4c2;
  border-radius: 25px;
  height: 2em;
  text-align: center;
  padding-top: 0.25em;
  cursor: pointer;
}

.submitButton2 {
  color: #ffffff;
  background: #06225c;
  border-radius: 25px;
  height: 2em;
  text-align: center;
  padding-top: 0.25em;
  cursor: pointer;
}

.customBorder {
  border: 1px solid #06225c;
  border-radius: 25px;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 2em;
  background: #ffffff;
}

.attention {
  color: #fbff00;
}

.attention2 {
  color: #ff9100;
}

.error {
  color: #ff0055;
}

.affirmative {
  color: #63d4c2;
}

.noticeNormal {
  color: #4cafea;
  font-weight: 600;
}

.noticeError {
  color: #ff0055;
  font-weight: 600;
}

.table {
  color: #b4b4b4;
}

.spacer {
  margin-top: 2em;
}

.spacerLg {
  margin-top: 5em;
}

.spacerLeftSmall {
  margin-left: 1em;
}

.lineDivider {
  border: 1px solid #b4b4b4;
}

.sectionDivider {
  height: 0.5em;
  background: #06225c;
  margin-top: 0.5em;
  margin-bottom: 1em;
}

.downloadIcon {
  height: 1.5em;
  width: 1.5em;
  margin-left: 1em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.downloadIconRight {
  height: 1.5em;
  width: 1.5em;
  margin-left: 0.5em;
  margin-bottom: 0.5em;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.falseAnchor {
  color: #007bff;
  cursor: pointer;
}

.falseAnchor:hover {
  color: #0056b3;
  cursor: pointer;
}

.ag-cell {
  color: #06225c !important;
}

.ag-header-cell-text {
  color: #06225c;
}

.svgPngMarginRight {
  margin-right: 5em;
}

/* app navbar */

.navbarMain {
  background: #06225c;
  margin-right: 0em;
  padding-left: 2em;
}

.navbarLink, .navbarLink:hover {
  color: #ffffff;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  z-index: 20;
}

.navbarLinkSelected, .navbarLinkSelected:hover {
  color: #4cafea;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  font-weight: 500;
  z-index: 20;
}

.navbarTitle, .navbarTitle:hover {
  color: #ffffff;
  margin-left: 1em; 
  margin-right: -4em;
  cursor: pointer;
  text-decoration: none;
}

/* end app navbar */

/* landing */

.inputFieldLabels {
  border: 1px solid #06225c;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  text-align: center;
  padding-top: 0.1em;
  font-size: 0.9em;
}

.inputFieldText {
  margin-top: 0.3em;
}

#emailLanding, #jobidLanding {
  border: 1px solid #06225c;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 1em;
  padding-right: 2em;
  background: #ffffff;
  color: #06225c;
  height: 2em;
}

/* end landing */

/* upload */

.yesNoButton {
  border: 2px solid #2a45af;
  cursor: pointer;
  text-align: center;
}

.yesNoButtonSelected {
  background: #2a45af;
  border: 2px solid #2a45af;
  cursor: pointer;
  text-align: center;
}

.speciesSvg {
  width: 3em;
  display: block;
  margin: auto;
  cursor: pointer;
}

.experimentModality {
  cursor: pointer;
  padding-left: 1em;
  padding-right: 1em;
}

.experimentModalitySelected {
  cursor: pointer;
  border: 2px solid #06225c;
  border-radius: 25px;
  padding-left: 1em;
  padding-right: 1em;
}

.instructionJustifyText {
  margin-left: 0.35em;
}

#noButton {
  margin-left: 1em;
}

#dropboxUploadStandard {
  border: 2px solid #06225c;
  border-radius: 25px;
  height: 20em;
  text-align:center;
}

#dropboxUploadError {
  border: 2px solid #06225c;
  border-radius: 25px;
  height: 19.5em;
  text-align:center;
  color: #f6003a;
}

#inputFileLabel {
  background: #ffffff;
  border-color: #06225c;
  color: #06225c;
  overflow: auto;
}

#selectedSpecies {
  color: #63d4c2;
}

#cancerTypeDropdown {
  background: #ffffff;
  border-color: #06225c;
  color: #06225c;
}

#loadExampleData, #downloadExampleData, #loadExampleGmt {
  cursor: pointer;
  font-style: italic;
  font-size: 0.85em;
}

#downloadGmtTxtAlign {
  text-align: end;
  margin-left: 1.5em;
  cursor: pointer;
  font-style: italic;
  font-size: 0.85em;
}

#advancedOptions {
  cursor: pointer;
  font-style: italic;
  font-size: 1em;
  font-weight: 600;
}

.bold {
  font-weight: bold;
}

/* end upload */

/* advanced options */

#clearSignatureButton {
  text-align: end;
  font-size: 0.9em;
  margin-left: 1.5em;
  cursor: pointer;
}

/* end advanced options */

/* view results */

.rowPadAlign {
  margin-left: 0px !important;
}

#jobIdTextField {
  border-radius: 25px;
  background: #ffffff;
  border-color: #06225c;
  color: #06225c;
  padding-left: 1em;
  width: 100%;
  font-size: 1.5em;
}

/* end view results */

/* advance options */

#customFile {
  margin-left: 1em;
}

/* end advance options */

/* results navbar */

.navbarResults {
  /* background: #06225c; */
  /* color: #ffffff; */
  border: 1px solid #06225c;
  border-radius: 25px;
  margin-right: 0.5em;
  width: 13em;
  text-align: center;
}

.optionResults {
  cursor: pointer;
}

.resultsNavbarSelected {
  /*color: #4cafea;*/
  text-decoration: underline;
  font-weight: 900;
}

.resultsNavbarUnselected {

}

.otherAnalyses {
  background: #4cafea;
  color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  width: 13.1em;
  margin-top: 0.25em;
  margin-left: -0.1em;
  transition: all 1s;
}

.otherAnalyses:hover, .otherAnalysesSelected {
  background: #06225c;
  border: 1px solid #4cafea;
  border-radius: 25px;
  color: #4cafea;
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
  width: 12em;
  margin-top: 0.25em;
  /*margin-right: 26em;*/
  margin-left: 0.5em;
  font-weight: 900;
}

.navbarResults0 {
  background: #06225c;
  color: #ffffff;
}

.navbarResults1 {
  background: #4cafea;
  color: #ffffff;
}

.navbarResults2 {
  background: #c2d7ec;
  color: #06225c;
}

.navbarResults3 {
  background: #c2d7ec;
  color: #06225c;
}

.navbarResults4 {
  background: #c2d7ec;
  color: #06225c;
}

.navbarResults5 {
  background: #a0cd63;
  color: #ffffff;
}

/* end results navbar */

/* renderTimeXR */

.viewNormalizeButton {
  border: 1px solid #06225c;
  background-color: #06225c;
  color: #ffffff;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
}

/* end renderTimeXR */

/* banner */

#bannerSubtext {
  position: relative;
  top: -0.9em;
  z-index: 10;
  font-size: 0.27em;
  text-align: center;
}

/* end banner */

/* footer */

.footerLinks {
  text-align: center;
}

/* end footer */

/* StatusUpdates */

.loadingBarBorder {
  border: 1px solid #b4b4b4;
  height: 1em;
  margin-left: 0em;
}

.loadingBar {
  background-color: #4cafea;
  height: .9em;
  width: 0%;
  transition: width 2s;
}

.loadingBarError {
  background-color: #ff0055;
  height: .9em;
  width: 0%;
  transition: width 2s;
}

/* end StatusUpdates */

/* about */

.scrollTableNotice {
  color: #269ce3;
  font-size: 0.9em;
  font-weight: bold;
  margin-top: -1em;
  text-align: end
}

/* end about */

@media only screen and (max-width: 559px) {
    /* navbar */
    .col-1x {
      width: 100%;
      margin-left: -0.5em;
    }
  
    .spacerLeft {
      margin-left: 0%;
    }
  
    .navAboutSpacer {
      margin-left: 0em;
    }

    /* end navbar */
  
    /* banner */
  
    #banner {
      height: 13em;
      width: 100%;
      color: #ffffff;
    }
    
    #bannerTitle {
      position: absolute;
      top: 0.1em;
      z-index: 10;
      font-size: 4em;
      text-align: center;
      font-weight: 500;
      color: #ffffff;
    }
  
    /* end banner */
  
    /* upload */
  
    .showInstructionsButton {
      border: 2px solid #06225c;
      background-color: #06225c;
      font-size: 0.9em;
      width: 40.667%;
      color: #ffffff;
      border-radius: 25px;
      cursor: pointer;
      text-align: center;
      padding-left: 0.5em;
      padding-right: 0.5em;
      padding-top: 0.25em;
      padding-bottom: 0.25em; 
      font-weight: 500;
    }
    
    .showTcgaButton {
      border: 2px solid #06225c;
      background-color: #06225c;
      font-size: 0.9em;
      width: 40.667%;
      color: #fbff00;
      border-radius: 25px;
      cursor: pointer;
      text-align: center;
      padding-left: 0.5em;
      padding-right: 0.5em;
      padding-top: 0.25em;
      padding-bottom: 0.25em; 
      margin-left: 0.35em;
      font-weight: 500;
    }

    #dropboxUploadStandard {
      border: 2px solid #06225c;
      border-radius: 25px;
      height: 25em;
      text-align:center;
    }
  
    /* end upload */
  
    /* viewresults */
  
    .otherAnalysesBackground {
      border: 1px solid rgb(71, 71, 71);
      border-radius: 10px;
      background: #ffffff;
      margin-top: 0.25em;
      margin-left: 0em;
      padding-bottom: 0.5em;
      width: 13.1em;
      display: none;
    }
  
    /* end viewresults */
  
  }

@media only screen and (min-width: 600px) and (max-width: 768px) {
  /* navbar */
  .col-1x {
    width: 14%;
    margin-left: -0.5em;
  }

  .spacerLeft {
    margin-left: 1%;
  }

  .navAboutSpacer {
    margin-left: 0em;
  }
  
  /* end navbar */

  /* banner */

  #banner {
    height: 13em;
    width: 100%;
    color: #ffffff;
  }
  
  #bannerTitle {
    position: absolute;
    top: 0.3em;
    z-index: 10;
    font-size: 4.5em;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
  }

  /* end banner */

  /* upload */

  .showInstructionsButton {
    border: 2px solid #06225c;
    background-color: #06225c;
    font-size: 0.9em;
    width: 18.667%;
    color: #ffffff;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em; 
    font-weight: 500;
  }
  
  .showTcgaButton {
    border: 2px solid #06225c;
    background-color: #06225c;
    font-size: 0.9em;
    width: 18.667%;
    color: #fbff00;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em; 
    margin-left: 0.35em;
    font-weight: 500;
  }

  /* end upload */

  /* viewresults */

  .otherAnalysesBackground {
    border: 1px solid rgb(71, 71, 71);
    border-radius: 10px;
    background: #ffffff;
    margin-top: 0.25em;
    margin-left: 0em;
    padding-bottom: 0.5em;
    width: 13.1em;
    display: none;
  }

  /* end viewresults */

}

@media only screen and (min-width: 769px) and (max-width: 992px) {
  /* navbar */
  .col-1x {
    width: 12%;
  }

  .spacerLeft {
    margin-left: 10.667%;
  }

  .navAboutSpacer {
    margin-left: 0em;
  }
  /* end navbar */

  /* banner */

  #banner {
    height: 13em;
    width: 100%;
    color: #ffffff;
  }
  
  #bannerTitle {
    position: absolute;
    top: 0.0em;
    z-index: 10;
    font-size: 5em;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
  }

  /* end banner */

  /* upload */

  .showInstructionsButton {
    border: 2px solid #06225c;
    background-color: #06225c;
    width: 18.667%;
    color: #ffffff;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em; 
    font-weight: 500;
  }
  
  .showTcgaButton {
    border: 2px solid #06225c;
    background-color: #06225c;
    width: 18.667%;
    color: #fbff00;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em; 
    margin-left: 0.35em;
    font-weight: 500;
  }

  /* end upload */

  /* viewresults */

  .otherAnalysesBackground {
    border: 1px solid rgb(71, 71, 71);
    border-radius: 10px;
    background: #ffffff;
    margin-top: 0.25em;
    margin-left: -14em;
    padding-bottom: 0.5em;
    width: 13.1em;
    display: none;
  }

  /* end viewresults */
}

@media only screen and (min-width: 993px) and (max-width: 1024px) {
  /* navbar */
  .col-1x {
    width: 12%;
    margin-left: -0.5em;
  }

  .spacerLeft {
    margin-left: 12.667%;
  }

  .navAboutSpacer {
    margin-left: 0.5em;
  }
  /* end navbar */

  /* banner */

  #banner {
    height: 13em;
    width: 100%;
    color: #ffffff;
  }
  
  #bannerTitle {
    position: absolute;
    top: 0.3em;
    z-index: 10;
    font-size: 4.5em;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
  }

  /* end banner */

  /* upload */

  .showInstructionsButton {
    border: 2px solid #06225c;
    background-color: #06225c;
    width: 15.667%;
    color: #ffffff;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em; 
    font-weight: 500;
  }
  
  .showTcgaButton {
    border: 2px solid #06225c;
    background-color: #06225c;
    width: 15.667%;
    color: #fbff00;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em; 
    font-weight: 500;
  }

  .downloadExampleTxtAlign {
    text-align: end;
  }

  /* end upload */

  /* viewresults */

  .otherAnalysesBackground {
    border: 1px solid rgb(71, 71, 71);
    border-radius: 10px;
    background: #ffffff;
    margin-top: -1em;
    margin-left: 54em;
    padding-bottom: 0.5em;
    width: 13.1em;
    display: none;
  }

  /* end viewresults */

}

@media only screen and (min-width: 1025px) and (max-width: 1135px) {
  /* navbar */
  .col-1x {
    width: 12%;
    margin-left: -0.5em;
  }

  .spacerLeft {
    margin-left: 12.667%;
  }

  .navAboutSpacer {
    margin-left: 0.5em;
  }
  /* end navbar */

  /* banner */

  #banner {
    height: 13em;
    width: 100%;
    color: #ffffff;
  }

  #bannerTitle {
    position: absolute;
    top: 0.3em;
    z-index: 10;
    font-size: 4.5em;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
  }

  /* end banner */

  /* upload */

  .showInstructionsButton {
    border: 2px solid #06225c;
    background-color: #06225c;
    width: 15.667%;
    color: #ffffff;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em; 
    font-weight: 500;
  }

  .showTcgaButton {
    border: 2px solid #06225c;
    background-color: #06225c;
    width: 15.667%;
    color: #fbff00;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em; 
    font-weight: 500;
  }

  .downloadExampleTxtAlign {
    text-align: end;
  }

  /* end upload */

  /* viewresults */

  .otherAnalysesBackground {
    border: 1px solid rgb(71, 71, 71);
    border-radius: 10px;
    background: #ffffff;
    margin-top: -1em;
    margin-left: 54em;
    padding-bottom: 0.5em;
    width: 13.1em;
    display: none;
  }

  /* end viewresults */
}

@media only screen and (min-width: 1136px) {
  /* navbar */
  .col-1x {
    width: 8%;
  }

  .spacerLeft {
    margin-left: 39.667%;
  }

  .navAboutSpacer {
    margin-left: 0.5em;
  }
  /* end navbar */

  /* banner */

  #banner {
    height: 10em;
    width: 100%;
    color: #ffffff;
  }
  
  #bannerTitle {
    position: absolute;
    top: 0.0em;
    z-index: 10;
    font-size: 5em;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
  }

  /* end banner */

  /* upload */

  .showInstructionsButton {
    border: 2px solid #06225c;
    background-color: #06225c;
    width: 10.667%;
    color: #ffffff;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em; 
    font-weight: 500;
  }
  
  .showTcgaButton {
    border: 2px solid #06225c;
    background-color: #06225c;
    width: 10.667%;
    color: #fbff00;
    border-radius: 25px;
    cursor: pointer;
    text-align: center;
    padding-left: 0.5em;
    padding-right: 0.5em;
    padding-top: 0.25em;
    padding-bottom: 0.25em; 
    font-weight: 500;
  }

  .downloadExampleTxtAlign {
    text-align: end;
  }

  /* end upload */

  /* viewresults */

  .otherAnalysesBackground {
    border: 1px solid rgb(71, 71, 71);
    border-radius: 10px;
    background: #ffffff;
    margin-top:0.25em;
    margin-left: 40em;
    padding-bottom: 0.5em;
    width: 13.1em;
    display: none;
  }

  /* end viewresults */
}